<template>
</template>

<script>
  import * as api from "@/api/common"
  export default {
    name: "monitorMessage",
    data() {
      return {
      }
    },
    created() {
      if(this.$route.query.code) {
        api.message_h5_auth({code: this.$route.query.code}).then(res =>{
          if(res.data.result_code=='0') {
            localStorage.setItem("ajt_token", res.data.data.token);
            this.$router.push('/monitor_h5')
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
